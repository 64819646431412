import React from 'react';
import { Router } from '@reach/router';

// import css for react-awesome-button package
import 'react-awesome-button/dist/themes/theme-blue.css';

import './App.css';
import Home from './views/Home';
import AppInfo from './views/AppInfo';
import AboutUs from './views/AboutUs';
import Merch from './views/Merch';
import FindOutMore from './views/FindOutMore';
import FourOFour from './views/FourOFour';
import NavBar from './components/NavBar';

const App = () => {
  return (
    <div className="App">
      <NavBar />
      <Router id="page-wrapper">
        <Home path="/" />
        <AppInfo path="/the-app" />
        <AboutUs path="/about-us" />
        <Merch path="/merch" />
        <FindOutMore path="/find-out-more" />
        <FourOFour default path="/404" />
      </Router>
    </div>
  );
};

export default App;
