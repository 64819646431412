import React from 'react';
import { RouteComponentProps } from '@reach/router';

import socialMedia from '../assets/undraw-svgs/social_media.svg';
import '../css/InfoPages.css';

//random event style photos in background on this pages
//or a phone mockup with app screenshot

const AppInfo = (props: RouteComponentProps) => {
  return (
    <div id="app-info" className="page-container">
      <div className="content-wrap corners">
        <div className="svg-wrap">
          <img src={socialMedia} alt="App Screen" />
        </div>
        <div className="text-wrap">
          <h1>The App</h1>
          <p>
            You know the struggle, the one when you have a birthday party, you
            look AMAZING and you want to share your pictures from the night (and
            of how good you looked) with the world. You look in your camera
            roll, but you have no photos!
          </p>
          <p>
            You then spend the next hour asking everyone, and I mean everyone,
            if they have any perfect shots of you, and have to wait, wait and
            wait, for them to reply. Hoping that someone, anyone, got that
            candid shot of you in your birthday glad rags, perfect for the
            ‘gram. We’ve all been there.
          </p>
          <p>
            Well, we’re here to help. We’re creating a space where you can
            create and plan your perfect event. Invite all your friends and most
            importantly get all photos from the night uploaded into one social
            space. You can see them all. Every perfect shot of you. Your
            friends. Everyone. All in one place and ready to be shared with the
            world both directly from the app or downloaded for some final
            touch-ups.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppInfo;
