import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import dompurify from 'dompurify';

import '../css/InfoPages.css';

const sanitizer = dompurify.sanitize;

const MAILCHIMP_SUBSCRIBE_URL =
  'https://bezyl.us10.list-manage.com/subscribe/post?u=ba682d09a3f5da2f2506eda90&amp;id=aaf979fec8';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const onEmailChange = (email: string, emailValid: Function) => {
  emailValid(EMAIL_REGEX.test(email));
};

const FindOutMore = (props: RouteComponentProps) => {
  const [getEmail, setEmail] = useState('');
  const [isValid, setValid] = useState(false);

  return (
    <div id="find-out-more" className="page-container">
      <div className="content-wrap corners">
        <div className="text-wrap">
          <h1>Find Out More</h1>
          <p>
            If you want to keep up to date with our progress and get exclusive
            updates about our release, sign up to our newsletter.
          </p>
          <p>
            Just provide us your email below and we will send you updates as
            soon as we have them.
          </p>
          <MailchimpSubscribe
            url={MAILCHIMP_SUBSCRIBE_URL}
            render={({ subscribe, status, message }) => (
              <div>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    subscribe({ EMAIL: getEmail });
                  }}
                >
                  <input
                    type="text"
                    name="email"
                    className="emailInput"
                    onChange={(e) => {
                      const email = e.target.value;
                      setEmail(email);
                      onEmailChange(email, setValid);
                    }}
                  />
                  <input
                    disabled={!isValid}
                    type="submit"
                    value="Sign Up"
                    className="btnSubmit"
                  />
                </form>
                {status === 'sending' && (
                  <div style={{ color: 'blue' }}>Sending...</div>
                )}
                {status === 'error' && (
                  <div
                    style={{ color: 'red' }}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(String(message)),
                    }}
                  />
                )}
                {status === 'success' && (
                  <div style={{ color: 'green' }}>Subscribed!</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default FindOutMore;
