import React, { useState } from 'react';
import { Link } from '@reach/router';
import logo from '../logo.svg';
import '../css/NavBar.css';
import '../views/AppInfo';

//TODO: fix issue on mobile where the bezyl logo is pushed off screen by the chrome top bar

const desktopNav = () => {
  return (
    <div>
      <ul id="Nav-list">
        <li>
          <Link className="nav-link" to="/the-app">
            The App
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/about-us">
            About Us
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/merch">
            Merchandise
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/find-out-more">
            Find Out More
          </Link>
        </li>
      </ul>
    </div>
  );
};

const mobileNav = () => {
  return (
    <ul id="Nav-list">
      <li>
        <Link className="nav-link" to="/the-app">
          The App
        </Link>
      </li>
      <li>
        <Link className="nav-link" to="/about-us">
          About Us
        </Link>
      </li>
      <li>
        <Link className="nav-link" to="/merch">
          Merchandise
        </Link>
      </li>
      <li>
        <Link className="nav-link" to="/find-out-more">
          Find Out More
        </Link>
      </li>
    </ul>
  );
};

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const isMobile = window.innerWidth <= 600;
  return (
    <div
      className={openMenu ? 'navbar show' : 'navbar hide'}
      onClick={() => setOpenMenu(!openMenu)}
    >
      <div className="logo">
        <img
          src={logo}
          className="App-logo"
          alt="Bezyl logo"
          onClick={() => setOpenMenu(!openMenu)}
        />
        <div className="vl"></div>

        <Link className="nav-link" to="/">
          <h1 className="App-title">Bezyl</h1>
        </Link>
      </div>
      {isMobile ? mobileNav() : desktopNav()}
    </div>
  );
};

export default NavBar;
