import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Typist from 'react-typist';

import starwars from '../assets/not-the-page.gif';
import '../css/InfoPages.css';

const FourOFour = (props: RouteComponentProps) => {
  return (
    <div id="fof" className="page-container">
      <div className="fof-wrap">
        <img src={starwars} alt="Star wars gif" />
        <Typist>This is not the page you're looking for...</Typist>
      </div>
    </div>
  );
};

export default FourOFour;
