import React from 'react';
import LazyLoad from 'react-lazyload'; //seems to work but pushes the image columns up to the top right off the page
import { RouteComponentProps } from '@reach/router';
import '../css/Home.css';

/**
 * Function that shuffles an array of integers, currently to randomise home screen image order
 *
 * TODO: move to a helper file
 *
 * @param a array of integers to be shuffled
 */
const shuffleIntArray = (a: number[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const randomArray = Array.from(new Array(111), (x, i) => i + 1);
const imageArray = shuffleIntArray(randomArray);

console.log({ imageArray });

const generateEventImages = (colIdx: number) => {
  const images = [];
  for (let i = 1; i <= 12; i++) {
    images.push(
      <div className="event-container" key={`column${colIdx}_container${i}`}>
        <LazyLoad height="16vh">
          <img
            className="event-image"
            key={`column${colIdx}_image${i}`}
            alt="event"
            src={`https://bezyl.co/assets/events/sample_${
              imageArray[(colIdx * 12 + i) % 111]
            }.png`}
          ></img>
        </LazyLoad>
      </div>,
    );
  }
  return images;
};

const generateEventColumns = () => {
  const columns = [];
  for (let i = 0; i < 20; i++) {
    columns.push(
      <div className="event-column" key={`column${i}`}>
        {generateEventImages(i)}
      </div>,
    );
  }
  return columns;
};

const Home = (props: RouteComponentProps) => {
  return (
    <div className="home-container">
      <div className="app-viewport">
        <div
          className="app-background"
        >
          {generateEventColumns()}
        </div>
      </div>
      <div className="announcement-width">
        <div className="announcement-container">
          <h2 className="slogan-text">Photos, Events, Privately</h2>
          <h4 className="announcement-text">Coming Spring 2021</h4>
          <h5 className="delay-explanation-text">Due to current global circumstances we have decided to delay release of our app, in hope of greener pastures in 2021.</h5>
        </div>
      </div>
    </div>
  );
};

export default Home;
