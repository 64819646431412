import React from 'react';
import { RouteComponentProps } from '@reach/router';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
// @ts-ignore
import { AwesomeButtonSocial } from 'react-awesome-button';

import programmer from '../assets/undraw-svgs/programming.svg';
import '../css/InfoPages.css';

const AboutUs = (props: RouteComponentProps) => {
  return (
    <div id="about-us" className="page-container">
      <div className="content-wrap corners">
        <div className="text-wrap">
          <h1>About Us</h1>
          <h2>Our Company</h2>
          <p>
            Here at Bezyl nothing is more important to us than your experiences.
            That’s why we are working hard to make sure your experience with our
            app is as awesome as the events you’re hosting with it. We value your
            privacy and security, that’s why we won’t abuse your information or
            exploit your data for our own gain. We want to bring people
            together in a safe space that everyone can share and enjoy.
          </p>
        </div>
        <span className="content-row">
          <div className="socials-wrapper">
            <h2>Social Media</h2>

            <span className="social-icons">
              <AwesomeButtonSocial
                type="instagram"
                url="https://www.instagram.com/bezyl.co/"
                className="social-btn"
              >
                Follow Us
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="twitter"
                url="https://www.twitter.com/bezyl_co/"
              >
                Follow Us
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="linkedin"
                url="https://www.linkedin.com"
                className="social-btn"
              >
                Connect
              </AwesomeButtonSocial>
            </span>
          </div>
          <div className="svg-wrap">
            <img src={programmer} alt="a programmer" />
          </div>
        </span>
      </div>
    </div>
  );
};

export default AboutUs;
