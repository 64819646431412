import React from 'react';
import { RouteComponentProps } from '@reach/router';
// @ts-ignore
import { AwesomeButton } from 'react-awesome-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

import "../css/awesome-button.css";

import onlineShopping from '../assets/undraw-svgs/online_shop.svg';
import '../css/InfoPages.css';

const Merch = (props: RouteComponentProps) => {
  return (
    <div id="merch" className="page-container">
      <div className="content-wrap corners">
        <div className="text-wrap">
          <h1>Merch</h1>
          <p>
            We are working hard to get our app made. Support us as we work by
            buying one of our logo T-shirts or tote bags from our merchandise
            shop.
          </p>
          <p>Click here to be transported</p>
          <AwesomeButton
            size="large"
            type="primary"
            href="https://bezyl.teemill.com/"
          >
            <FontAwesomeIcon className="merch-btn" icon={faShoppingBag} />
          </AwesomeButton>
        </div>
        <div className="svg-wrap">
          <img src={onlineShopping} alt="online shopping" />
        </div>
      </div>
    </div>
  );
};

export default Merch;
